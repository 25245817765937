<template>
    <div class="row">
        <div class="col-12">
            <div class="card card m-b-0">
                <div class="chat-main-box">
                    <div class="chat-right-aside" style="width:100%">
                        <div class="chat-main-header">
                        </div>
                        <div class="chat-rbox static-content">
                            <div class="wrapp-chat-content" data-scrollbar="true" tabindex="1"
                                style="overflow: hidden; outline: none;">
                                <div class="scroll-content">
                                    <div class="contact-add">
                                        <form class="form-horizontal form-material">
                                            Daftar Status
                                            <div class="clearfix"></div>
                                            <br>
                                            <div class="clearfix"></div>
                                            <div class="wrapp-input-contact m-t-20">
                                                <template>
                                                    <div class="list-auto-template mb-5">
                                                        <div class="row">
                                                            <div class="col col-md-4 col-lg-4 wrapp-card" v-for="story in storyList" :key="story._id">
                                                                <div class="card">
                                                                    <video v-if="story.contentType == 'video'" class="card-img-top" :src="story.url" controls></video>
                                                                    <img v-if="story.contentType == 'image'" class="card-img-top" :src="story.url" alt="story" />
                                                                    <div class="card-body">
                                                                        <div class="card-text">
                                                                            <div>
                                                                                  <v-icon title="Tanggal Diupload">mdi-calendar</v-icon>
                                                                                <span class="ml-1">
                                                                                    {{ utcToLocal(story.createdOn, 'lll') }}
                                                                                </span>
                                                                            </div>
                                                                            <div>
                                                                                <!-- v-if="story.viewers.length > 0" -->
                                                                                <v-tooltip bottom >
                                                                                    <template v-slot:activator="{ on, attrs }">
                                                                                        <div v-bind="attrs" v-on="on">
                                                                                            <v-icon title="Dilihat">mdi-eye</v-icon>
                                                                                            <span class="ml-1">
                                                                                                {{ story.viewers.length }}
                                                                                            </span>
                                                                                        </div>
                                                                                    </template>
                                                                                    <div v-for="(item) in story.viewers" :key="item.id">
                                                                                        <span>
                                                                                            <v-icon class="icon-sort-filter text-white" left>
                                                                                                mdi-account
                                                                                            </v-icon>
                                                                                            {{ item.name }}
                                                                                        </span>
                                                                                    </div>
                                                                                </v-tooltip>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row" v-if="isLoadingList">
                                                            <div class="col">
                                                                <v-progress-linear indeterminate
                                                                    height="10"
                                                                    color="primary"
                                                                ></v-progress-linear>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                                <div class="scrollbar-track scrollbar-track-x" style="display: none;">
                                    <div class="scrollbar-thumb scrollbar-thumb-x"
                                        style="width: 661px; transform: translate3d(0px, 0px, 0px);"></div>
                                </div>
                                <div class="scrollbar-track scrollbar-track-y" style="display: block;">
                                    <div class="scrollbar-thumb scrollbar-thumb-y"
                                        style="height: 445.289px; transform: translate3d(0px, 0px, 0px);"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <SuccessNotif />
    </div>
</template>
<script>
    import axios from 'axios'
    import Scrollbar from 'smooth-scrollbar'
    import MainLayout from "../layouts/MainLayout"
    import SuccessNotif from "../components/SuccessNotif"
    import { mapState } from 'vuex'
    import Toast from '../plugins/vuenotification'

    const { commonpagesetup, mainuisetup } = require('../utils/ui-setup')
    const $ = require("jquery")

    var moment = require('moment');
    var messageScrollBar = null;

    export default {
        computed: {
            ...mapState({
                sessionInfo: state => state.session.sessionInfo
            })
        },
        data: function () {
            return {
                page: 1,

                storyList: Array(),

                hasMoreData: false,
                isLoadingList: false,

                rules: {
                    required: [(v) => !!v || ""],
                    requiredObject: [(v) => Object.keys(v).length > 0 || ""],
                    requiredArray: [(v) => v.length > 0 || ""],
                },
            }
        },
        watch: {
            sessionInfo: function (val) {
                if (!this.$helpers.isBlank(val) && val.isLoggedIn) {
                    this.loadInitialData();
                }
            },
        },
        methods: {
            getStoryList: async function() {
                try {
                    this.isLoadingList = true

                    const params = {
                        page: this.page,
                        size: 9,
                        sort: "createdOn,desc"
                    }

                    const { data } = await axios.get(process.env.VUE_APP_API_URL + '/message-story', {
                        params
                    });

                    this.hasMoreData = data.page.number < data.page.totalPages
                    this.storyList = [...this.storyList, ...data.content]
                    this.page++

                    this.isLoadingList = false
                } catch (e) {
                    this.isLoadingList = false
                    if(e.response && e.response.data.message) {
                        Toast.showToast("error", e.response.data.message)
                    } else {
                        Toast.showToast("error", "Error mengambil data story")
                    }
                }
            },

            loadInitialData: async function() {
                await this.getStoryList()
            },

            utcToLocal: function(date, format) {
                var utcLocal = moment(date).local().format(format);
                return utcLocal;
            },

            utcToLocalView: function(date, format) {
                var utcLocal = moment(date).format(format);
                return utcLocal;
            },

            localToUtc: function(date, format) {
                var utcLocal = moment(date).utc().format(format);
                return utcLocal;
            }
        },

        created: function () {
            this.$emit('update:layout', MainLayout);
        },

        mounted: function () {
            Scrollbar.initAll();

            messageScrollBar = Scrollbar.get($('.wrapp-chat-content').get()[0]);

            var self = this;

            messageScrollBar.addListener((status) => {
                if (!self.isLoadingfalse && self.hasMoreData && status.offset.y == messageScrollBar.limit.y) {
                    setTimeout(() => self.getStoryList(), 300);
                }
            });

            if (!this.$helpers.isBlank(this.sessionInfo) && this.sessionInfo.isLoggedIn) {
                this.loadInitialData();
            }
            try {
                mainuisetup();
                commonpagesetup();
            } catch (err) {
                console.log(err);
            }
        },
        components: {
            // ErrorMessage,
            SuccessNotif,
        }
    }
</script>